import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Signup from "./containers/Signup";
import Login from "./containers/Login";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import NotFound from "./containers/NotFound";
import NewCard from "./containers/NewCard";
import CardEditor from "./containers/CardEditor";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>

      <UnauthenticatedRoute exact path="/signup">
        <Signup />
      </UnauthenticatedRoute>

      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>

      <AuthenticatedRoute exact path="/newcard">
        <NewCard />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/card/:id">
        <CardEditor />
      </AuthenticatedRoute>

      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}