export function querySelectorHas(parent, child, doc) {
  let mDoc = (doc) ? doc : document;
  return [].filter.call(mDoc.querySelectorAll(parent), function (elem) {
    return elem.querySelector(child)
  });
}

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export async function asyncFilter(arr, predicate) {
  // arr.reduce(async (memo, e) => await predicate(e) ? [...await memo, e] : memo, []);
  arr.reduce(async (memo, e) =>
		[...await memo, ...await predicate(e) ? [e] : []]
	, []);
}

export const Timeout = (time) => {
  let controller = new AbortController();
  setTimeout(() => controller.abort(), time * 1000);
  return controller;
};

export function downloadFile(fileName, fileData) {
  const $a = document.createElement("a"); //Create <a>
  var blob = new Blob(["\ufeff", fileData]);
  var url = URL.createObjectURL(blob);
  $a.href = url;
  $a.download = fileName; //File name Here
  $a.click(); //Downloaded file
}

export function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

export async function retry(theFunc, retries = 3, sleepTime) {
  console.log(`retries ${retries}`)
  try {
    console.log(`running ${theFunc}`)
    await theFunc();
  } catch (err) {
    if (retries === 0) {
      console.error('there was an error')
    } else {
      //sleep(sleepTime || 1000)
      console.log(`retrying ${theFunc}`)
      setTimeout(await retry(theFunc, --retries, sleepTime), sleepTime);
      console.log(`done retrying ${theFunc}`)
    }
  }
}
