import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useBetween } from 'use-between';
import Form from "react-bootstrap/Form";
import { useHistory, useParams } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields, useLocalStorage, useSessionStorage, } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import { API, Auth } from "aws-amplify";
import { asyncForEach } from "../libs/functionsLib";
import config from '../config';
import { loadStripe } from "@stripe/stripe-js";
import { Container, Row, Col, ButtonGroup, Button, Modal } from "react-bootstrap"
import CardViewer from "../components/CardViewer";
import CardEditForm from "../components/CardEditForm";
import flatten, { unflatten } from 'flat';
import './CardEditor.scss';

export default function CardEditor() {

  const history = useHistory();

  const { id } = useParams();

  const [userObj, setUserObj] = useSessionStorage('userInfo', {});
  const [cardInfo, setCardInfo] = useState();
  const [currentButton, setCurrentButton] = useState('common');
  const [modalShow, setModalShow] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formKey, setFormKey] = useState();

  useEffect(() => {
    async function onLoad() {
      //console.log('retrieving cardinfo')
      const info = await API.get('enteraqt', `/cards/${userObj.id}/${id}`, {});
      setCardInfo(info);
    }
    onLoad();
  }, []);

  function reloadViewer() {
    const newsrc = document.querySelector('iframe')?.src;
    document.querySelector('iframe')?.setAttribute('src', newsrc);
  }

  function handleFragmentSelect(sectionName) {
    setCurrentButton(sectionName);
  }

  function drawFragmentButtons() {
    const unorderedFragments = cardInfo.fragments;
    const orderedFragments = [];

    let fragment = unorderedFragments.find(element => !element.after);
    if (fragment) {
      orderedFragments.push(fragment);
      for (let i = 1; i < unorderedFragments.length; i++) {
        fragment = getNextFragment(fragment, unorderedFragments);
        orderedFragments.push(fragment);
      }
      return (
        <>
          {
            orderedFragments.map((fragment, i) => {
              return (
                <Button onClick={() => handleFragmentSelect(fragment.id)} key={fragment.id}>
                  {
                    (fragment.type === 'richtext') ? 'Rich Text' : 'Image'
                  }
                </Button>
              );
            })
          }
        </>
      );
    }
  }

  function getNextFragment(currentFragment, fragments) {
    const id = currentFragment?.id;
    let foundFragment = currentFragment;
    for (let i in fragments) {
      const fragment = fragments[i];
      const after = fragment.after;
      if (after && after === id) {
        foundFragment = fragment;
        break;
      } else
        if (!after) {
          foundFragment = currentFragment;
          continue; //Haven't found one yet, but can't assume this is the right fragment.
        }
    }
    return foundFragment;
  }

  function getLastFragment(fragments) {
    let fragment = fragments[0];
    for (let i = 0; i < fragments.length; i++) {
      fragment = getNextFragment(fragment, fragments);
    }
    return fragment;
  }

  async function createNewFragment(fragments, type) {
    const lastFragment = getLastFragment(fragments);
    const details = {
      type,
      after: lastFragment?.id,
    }
    const cardInfo = await API.post('enteraqt', `/cards/${userObj.id}/${id}/fragments`, {
      body: details,
    });
    setCardInfo(cardInfo);
    setFormKey(new Date().getMilliseconds());
  }




  async function handleSave(fields, $imageUploadComponents) {
    setIsLoading(true)
    fields = await saveImages($imageUploadComponents, fields);
    const unflattenedFields = unflatten(fields)
    await API.put('enteraqt', `/cards/${userObj.id}/${id}`, {
      body: unflattenedFields,
    });
    setCardInfo(unflattenedFields);
    setIsLoading(false)
    reloadViewer();
  }

  async function getUploadPath(assetId) {
    const path = await API.get('enteraqt',
      `/assets/${userObj.id}/${cardInfo.cardId}/${assetId}/path`,
      {});
    return path;
  }

  async function saveImages($imageUploadComponents, fields) {
    await asyncForEach($imageUploadComponents, async ($imageUploadComponent) => {
      const $imageUploadInput = $imageUploadComponent.querySelector('input[type="file"]');
      const id = $imageUploadInput.id;
      const file = $imageUploadInput.files[0];
      const removeCheckboxSelector = '.form-check-input';//`#${id}_delete`;
      const $deleteFileCheckbox = $imageUploadComponent.querySelector(removeCheckboxSelector);
      if (file && !$deleteFileCheckbox?.checked) {
        const uploadPathInfo = await getUploadPath(id);
        if (uploadPathInfo && uploadPathInfo.URL) {
          const response = await fetch(uploadPathInfo.URL, {
            method: 'PUT',
            body: file,
            headers: {
              'Content-Type': 'image/jpeg',
            },
          })
          fields[id] = id;
          $imageUploadInput.value = '';
        }
      } else if ($deleteFileCheckbox?.checked) {
        fields[id] = '';
        $deleteFileCheckbox.checked = false;
        await API.del('enteraqt',
          `/assets/${userObj.id}/${cardInfo.cardId}/${id}`,
          {});
      }
    });
    return fields;
  }





  function TypeSelectModal(props) {

    async function createNew(id) {
      const $type = document.querySelector('#new-fragment-type');
      const type = $type?.value;
      createNewFragment(cardInfo.fragments, type);
      props.onHide();
    }

    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Fragment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Select Type
          <Form.Control as="select" id="new-fragment-type">
            <option value="richtext">Rich Text</option>
            <option value="image">Image</option>
          </Form.Control>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={createNew}>Add</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <Container className="card-editor">
        <Row className="editor-header">
          <Col sm={12}>
            <Button
              variant="outline-primary"
              onClick={() => history.push('/')}
            >
              ❮ Dashboard
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm={2}>
            <ButtonGroup vertical>
              <Button onClick={() => handleFragmentSelect('common')}>General</Button>
              <Button onClick={() => handleFragmentSelect('header')}>Header</Button>
              <Button onClick={() => handleFragmentSelect('welcome')}>Welcome</Button>
              <Button onClick={() => handleFragmentSelect('contactform')}>Contact Form</Button>
              {
                cardInfo &&
                drawFragmentButtons()
              }
              <Button variant="secondary" onClick={() => setModalShow(true)}>Add New</Button>
            </ButtonGroup>
          </Col>
          <Col sm={5}>
            {
              cardInfo &&
              <CardEditForm cardInfo={cardInfo} fields={flatten(cardInfo)} handleSave={handleSave} currentButton={currentButton} handleFragmentSelect={handleFragmentSelect} isLoading={isLoading} key={formKey} />
            }
          </Col>
          <Col sm={5}>
            <CardViewer />
          </Col>
        </Row>
        <TypeSelectModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </Container>
    </>
  );
}